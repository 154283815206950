import React, { Component } from 'react'
import '../css/contact.css'
class Contact extends Component {
  render() {
    return (
      <div id="contact">
        <h1 className="underline">Contact Us</h1>
            <img src="/images/main/hp1.JPG" alt="Mariners Court Hotel photo"/>
            <div id="contactInfo">
            <a href="https://goo.gl/maps/LaSx5bLmPT52" target="_blank"><i className="material-icons">home</i> 44-50 McElhone Street, Woolloomooloo NSW</a>
            <a href="tel:61293203800"><i className="material-icons">phone</i> Tel:+ 61 2 9320 3800</a>
            <a href="tel:61293203862"><i className="material-icons">print</i> Fax: + 61 293203862</a>
            <a href="mailto:reservations@marinerscourt.com.au"><i className="material-icons">email</i> reservations@marinerscourt.com.au</a>
        </div>

        {/* contact form */}

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.739649928984!2d151.21967011485256!3d-33.87059992658296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae729f86c307%3A0x9c010885411a091c!2s44+McElhone+St%2C+Woolloomooloo+NSW+2011!5e0!3m2!1sen!2sau!4v1555725379864!5m2!1sen!2sau" width="100%" height="400px" frameborder="0" style={{border:0}} allowfullscreen></iframe>
      </div>
    )
  }
}

export default Contact;