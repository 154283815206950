import React, { Component } from 'react'
import '../css/carousel.css'

class Carousel extends Component {
  render() {
    return (
      <div id="carouselFamily" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="bg" src="/images/main/IMG_2255.jpg" alt="First slide"/>
                <p>Family Room 1</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2260.jpg" alt="Second slide" />
                <p>Family Room 1</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2261.jpg" alt="Third slide" />
                <p>Family Room 1</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2188.jpg" alt="Fourth slide" />
                <p>Family Room 2</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2191.jpg" alt="Fifth slide" />
                <p>Family Room 2</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2193.jpg" alt="Sixth slide" />
                <p>Family Room 2</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2194.jpg" alt="Seventh slide" />
                <p>Family Room 2</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2326.jpg" alt="Eigth slide" />
                <p>Family Room 3</p>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2126.jpg" alt="Nineth slide" />
                <p>Family Room 3</p>
            </div>
        </div>

        <a class="carousel-control-prev" href="#carouselFamily" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselFamily" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
      </div>
    )
  }
}

export default Carousel;