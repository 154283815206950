import React, { Component } from 'react'
import '../css/about.css'
class About extends Component {
  render() {
    return (
      <div id="about">
        <h1 className="underline">About Us</h1>
        <img src="/images/main/hp2.jpg"></img>
        <p>
        The Mariners Court Hotel is one of Sydney's best kept secrets. This 3 and half star hotel is centrally located near the famous Woolloomooloo Finger 
        Wharf, local cafes and restaurants. The hotel is positioned only a short walk from the Art Gallery of NSW, Botanical Gardens, Circular Quay 
        and only a few minutes walk to the city centre and Kings Cross.
        <br/>
        <br/>
        The hotel has King bed, Queen bed and Twin rooms over 3 levels. All rooms feature a courtyard or individual balconies, 
        en-suite bathroom, tea and coffee making facilities, TV, direct dial telephones and free WI FI!
        <br/>
        <br/>
        The hotel has disabled facilities, elevator, sunny breakfast room with terrace, guest lounge with pool table and guest terrace on the 
        third level. A free hot buffet breakfast is included.
        </p>
        <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
      </div>
    )
  }
}

export default About;