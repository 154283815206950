import React, { Component } from 'react'

class Nav extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="logo">
          {/* <h1 className="mobileh1" style={{paddingRight:"40px"}}>Mariners Court Hotel</h1> */}
          <a href="/"><img className="mobilelogo" src="/images/main.png"/></a>
        </div>
        <i onClick={this.props.toggleMenu} className="fixedMenu material-icons">menu</i>
        <nav className="mobile-overlay" style={this.props.menuOpen ? {height: "100%"} : {height:"0%"}}>
        <a className="closeMenu" onClick={this.props.toggleMenu}><i className="material-icons" style={{margin:"0"}}>close</i></a>
            <div id="mobile-nav">
                <a href="/">Home</a>
                <a href="/about/">About</a>
                <a href="/rooms/">Rooms</a>
                <a href="/restaurants/">Restaurants</a>
                <a href="/places-to-see">Places to See</a>
                <a href="/gallery/">Gallery</a>
                <a href="/contact/">Contact</a>

                <div id="contact-links">
                  <a href="tel:61293203800"><i className="material-icons">phone</i> Call Us:+61 2 9320 3800</a>
                  <a href="mailto:reservations@marinerscourt.com.au"><i className="material-icons">email</i> reservations@</a>
                  <a href="https://goo.gl/maps/LaSx5bLmPT52" target="_blank"><i className="material-icons">location_on</i> Find us on Google Maps</a>
                </div>
            </div>
        </nav>

        <div id="desktopNav">
          <div className="logo">
          {/* <h1 style={{margin:"0"}}>Mariners Court Hotel</h1> */}
          <a href="/"><img src="/images/main.png" /></a>
          </div>
          <div style={{marginLeft: "auto"}}>
          <a href="/">Home</a>
          <a href="/about/">About</a>
          <a href="/rooms/">Rooms</a>
          <a href="/restaurants/">Restaurants</a>
          <a href="/places-to-see">Places to See</a>
          <a href="/gallery/">Gallery</a>
          <a href="/contact/">Contact</a>
          <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT"  target="_blank" style={{color:"white", fontSize:"14px", margin:"0"}}>Book Now</a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Nav;
