import React, { Component } from 'react'

import Carousel from './Carousel';
import '../css/home.css'
class Home extends Component {
  render() {
    return (
      <div id="home">
        <div id="display">
          <Carousel />
          <h2 className="p-intro">One of Sydney's Best Kept Secrets</h2>
          <a className="booking-button booking-button-mobile" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT"  target="_blank">Book Now</a>
        
          <div id="mainLinks-desktop">
            <div className="mainLinks">
            <div className="linkHolder">
            <a href="/rooms" className="linkItem margin-left blue"><div className="overlay blue" /><img src="/images/main/IMG_2318.jpg"/><div className="content"><i className="material-icons">local_hotel</i><br/>Rooms</div></a>
            <a href="/restaurants" className="linkItem margin-left yellow"><div className="overlay yellow"></div><img src="/images/main/P1030362.JPG"/><div className="content"><i className="material-icons">local_dining</i><br/>Restaurants</div></a>
            </div>
            <div className="linkHolder">
            <a href="/gallery" className="linkItem margin-right green"><div className="overlay green"></div><img src="/images/main/P1030332.JPG"/><div className="content"><i className="material-icons">photo_library</i><br/>Gallery</div></a>
            <a href="/places-to-see" className="linkItem margin-right red"><div className="overlay red"></div><img src="/images/places/lunapark.jpg"/><div className="content"><i className="material-icons">local_play</i><br/>Attractions</div></a>
            </div>
            </div>
        </div>
        </div>
        <p className="p-intro">The Mariners Court Hotel is one of Sydney's best kept secrets. This 3 and half star hotel is centrally located near the famous Woolloomooloo Finger 
        Wharf, local cafes and restaurants. The hotel is positioned only a short walk from the Art Gallery of NSW, Botanical Gardens, Circular Quay 
        and only a few minutes walk to the city centre and Kings Cross.</p> 
        <div id="mainLinks-mobile" className="mainLinks">
            <a href="/rooms" className="linkItem"><div className="overlay blue"></div><img src="/images/main/IMG_2318.jpg"/><div className="content"><i className="material-icons">local_hotel</i><br/>Rooms</div></a>
            <a href="/restaurants" className="linkItem"><div className="overlay yellow"></div><img src="/images/main/P1030362.JPG"/><div className="content"><i className="material-icons">local_dining</i><br/>Restaurants</div></a>
            <a href="/gallery" className="linkItem"><div className="overlay green"></div><img src="/images/main/P1030332.JPG"/><div className="content"><i className="material-icons">photo_library</i><br/>Gallery</div></a>
            <a href="/contact" className="linkItem"><div className="overlay red"></div><img src="/images/places/lunapark.jpg"/><div className="content"><i className="material-icons">local_play</i><br/>Attractions</div></a>
        </div>
        <a className="booking-button p-intro" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
      </div>
    )
  }
}

export default Home;
