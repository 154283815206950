import React, { Component } from 'react'

import '../css/rooms.css'
import CarouselTwin from './CarouselTwin';
import CarouselQueen from './CarouselQueen';
import CarouselFamily from './CarouselFamily';
class Rooms extends Component {

    state = {
      twinModal: false,
      queenModal: false,
      familyModal: false,
    }

    openModal = (modalType) => {

      switch (modalType) {
        case "twinModal":
          this.setState({...this.state, twinModal:true});
          break;
        
        case "queenModal":
          this.setState({...this.state, queenModal:true});
          break;

        case "familyModal":
          this.setState({...this.state, familyModal:true});
          break;

        default:
          return;
      }
    }

    closeModal = () => {
      this.setState({twinModal: false, queenModal: false, familyModal: false})
    }

    closeModalNotClicked = (e) => {
      if (e.target.className == "room-modal") {
        this.closeModal();
      }
    }

  render() {
    return (
      <div id="room">
        <h1 className="underline">Rooms</h1>
        
        <div id="roomType">
            <a onClick={() => this.openModal("twinModal")}>
            <img src="/images/main/IMG_1626.jpg"/>
            <h2>Standard Twin Room</h2><i className="material-icons">info</i>
            </a>
            <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
        </div>
        

        <div className="room-modal" style={this.state.twinModal ? {display:"block"} : {display:"none"}} onClick={this.closeModalNotClicked}>
          <div className="room-modal-content">
          <i class="closeModal" onClick={this.closeModal}>&times;</i>
            <h1>Standard Twin Room</h1>
            <CarouselTwin/>
            <h2>Description</h2>
            <p>2x Single Beds</p>
            <h2>Amenities</h2>
            <div className="flex">
            <ul>
              <li>Tea &amp; Coffee</li>
              <li>Shower</li>
              <li>Iron/Ironing board</li>
              <li>Television</li>
              <li>Ceiling Fans</li>
              <li>Lift/Elevator Access</li>
              <li>Linen and Towels</li>
              <li>Heating</li>
              <li>Cots Available</li>
            </ul>

            <ul>
              <li>Room Serviced Daily</li>
              <li>Telephone</li>
              <li>Air-conditioning</li>
              <li>Disabled Room</li>
              <li>Hairdryer</li>
              <li>Wifi Access</li>
              <li>Bar Fridge</li>
            </ul>
            </div>

            <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
          </div>
        </div>

        <div id="roomType">
            <a onClick={() => this.openModal("queenModal")}>
            <img src="/images/main/IMG_2318.jpg"/>
            <h2>Standard Queen Room</h2><i className="material-icons">info</i>
            </a>
            <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
        </div>

        <div className="room-modal" style={this.state.queenModal ? {display:"block"} : {display:"none"}} onClick={this.closeModalNotClicked}>
          <div className="room-modal-content">
          <i class="closeModal" onClick={this.closeModal}>&times;</i>
            <h1>Standard Queen Room</h1>
            <CarouselQueen/>
            <h2>Description</h2>
            <p>1x Queen Bed</p>
            <h2>Amenities</h2>
            <div className="flex">
            <ul>
              <li>Balcony</li>
              <li>Tea &amp; Coffee</li>
              <li>Shower</li>
              <li>Iron/Ironing board</li>
              <li>Television</li>
              <li>Ceiling Fans</li>
              <li>Lift/Elevator Access</li>
              <li>Linen and Towels</li>
              <li>Heating</li>
              <li>Cots Available</li>
            </ul>

            <ul>
              <li>Laundry Facilities</li>
              <li>Room Serviced Daily</li>
              <li>Telephone</li>
              <li>Air-conditioning</li>
              <li>Disabled Room</li>
              <li>Hairdryer</li>
              <li>Wifi Access</li>
              <li>Bar Fridge</li>
            </ul>
            </div>

            <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
          </div>
        </div>

        <div id="roomType">
            <a onClick={() => this.openModal("familyModal")}>
            <img src="/images/main/IMG_2255.jpg"/>
            <h2>Family Room</h2><i className="material-icons">info</i>
            </a>
            <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
        </div>

        <div className="room-modal" style={this.state.familyModal ? {display:"block"} : {display:"none"}} onClick={this.closeModalNotClicked}>
          <div className="room-modal-content">
          <i class="closeModal" onClick={this.closeModal}>&times;</i>
            <h1>Family Room</h1>
            <CarouselFamily/>
            <h2>Description</h2>
            <p>1x Double Bed &amp; 2x Single Beds</p>
            <h2>Amenities</h2>
            <div className="flex">
            <ul>
              <li>Kitchenette (Family Rooms 1 &amp; 2)</li>
              <li>Balcony (Family Rooms 1 &amp; 3)</li>
              <li>Patio (Family Room 2)</li>
              <li>Desk</li>
              <li>Tea &amp; Coffee</li>
              <li>Shower</li>
              <li>Iron/Ironing board</li>
              <li>Television</li>
              <li>Ceiling Fans</li>
              <li>Lift/Elevator Access</li>
              <li>Linen and Towels</li>
            </ul>

            <ul>
              <li>Microwave</li>
              <li>Room Serviced Daily</li>
              <li>Telephone</li>
              <li>Air-conditioning</li>
              <li>Disabled Room</li>
              <li>Hairdryer</li>
              <li>Wifi Access</li>
              <li>Bar Fridge</li>
              <li>Heating</li>
              <li>Cots Available</li>
            </ul>
            </div>

            <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
          </div>
        </div>

        <div className="extraInfo">
        <div className="infoList">
        <h3>Hotel Facilities</h3>
        <ul>
          <li>Complimentary hot buffet breakfast</li>
          <li>Laundry on first and second floor</li>
          <li>Public telephone</li>
          <li>Disabled bathroom facilities on each floor</li>
          <li>Garden deck outside Dining Room and roof deck on the third floor</li>
          <li>Air-conditioned Lounge, Dining Room and Recreation Room with a snooker table</li>
          <li>Luggage storage by arrangement (Until 4pm)</li>
          <li>Late check in by prior arrangement (Key Safe System)</li>
          <li>Wi-Fi available in all rooms (Free)</li>
        </ul>
        </div>

        <div className="infoList">
        <h3>Booking Conditions</h3>
        <ul>
          <li>Check in 2pm</li>
          <li>Check out 10am</li>
          <li>All bookings require credit card details to secure and guarantee the booking</li>
          <li>Cancellations 48 hours prior to arrival or a fee of one night accomodation will be charged</li>
          <li>Reception hours from 8am-6pm</li>
          <li>*After hours check in by our Key Safe System. Please call us to arrange your late check in or mention while booking and we will email you all the details required for a late check in</li>
        </ul>
        </div>
        </div>

        <a className="booking-button" href="https://www.thebookingbutton.com.au/properties/MARINERSCHDIRECT" target="_blank">Book Now</a>
      </div>
    )
  }
}

export default Rooms;