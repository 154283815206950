import React, { Component } from 'react'
import '../css/carousel.css'

class Carousel extends Component {
  render() {
    return (
      <div id="carouselQueen" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="bg" src="/images/main/IMG_2165.jpg" alt="First slide"/>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2318.jpg" alt="Second slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_1965.jpg" alt="Third slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2182.jpg" alt="Fourth slide" />
            </div>
        </div>

        <a class="carousel-control-prev" href="#carouselQueen" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselQueen" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
      </div>
    )
  }
}

export default Carousel;