import React, { Component } from 'react';
import '../css/gallery.css';

class Gallery extends Component {

  state = {
    mainImgSrc: "/images/main/hp3.jpg",
    imgOverlay: false
  }

  //closes modal if not clicked on when open
  closeModal = (e) => {
    console.log(e.target.id);
    if (e.target.id == "overlay"){
      this.toggleOverlay();
    }
  }

  currentDiv = (e) => {
    this.setState({
      ...this.state, mainImgSrc: e.target.src
    });
  }

  toggleOverlay = () => {
    this.setState({
      ...this.state, imgOverlay: false
    });
  }

  openOverlay = (e) => {
    this.setState({
      ...this.state, mainImgSrc: e.target.src, imgOverlay: true
    });
  }
  render() {
    return (
      <React.Fragment>
        <div id="galleryMain">
          <h1 className="underline alignLeft">Gallery</h1>
          <div className="desktopView">
            <img src={this.state.mainImgSrc} className="mainImage"/>

            <div id="gallery">
            <img src="/images/main/hp3.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_1626.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_1630.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_1828.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/P1030338.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030339.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030340.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030341.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030342.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_1965.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_1971.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2126.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/P1030362.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030363.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030367.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2134.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2135.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2143.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/P1030378.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030379.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030380.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030381.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030382.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030383.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030384.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/P1030385.JPG" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2151.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2153.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2154.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2157.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2159.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2165.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/20190503_122410.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/20190503_122325.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2182.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2188.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2191.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2193.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2194.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2195.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2255.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2260.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2261.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2318.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2326.jpg" onClick={this.currentDiv}/>
              <img src="/images/main/IMG_2333.jpg" onClick={this.currentDiv}/>
            </div>

            <div id="galleryOverlay">
              <div id="overlay" style={this.state.imgOverlay ? {display:"block"} : {display:"none"}} onClick={this.closeModal}>
                <div>
                  <img src={this.state.mainImgSrc} />
                  <a onClick={this.toggleOverlay}><i className="material-icons" style={{margin:"0"}}>close</i></a>
                </div>
              </div>
              <img src="/images/main/hp3.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_1626.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_1630.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_1828.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/P1030338.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030339.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030340.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030341.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030342.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_1965.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_1971.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2126.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/P1030362.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030363.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030367.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2134.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2135.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2143.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/P1030378.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030379.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030380.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030381.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030382.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030383.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030384.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/P1030385.JPG" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2151.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2153.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2154.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2157.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2159.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2165.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/20190503_122410.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/20190503_122325.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2182.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2188.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2191.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2193.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2194.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2195.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2255.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2260.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2261.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2318.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2326.jpg" onClick={this.openOverlay}/>
              <img src="/images/main/IMG_2333.jpg" onClick={this.openOverlay}/>
            </div>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default Gallery;