import React, { Component } from 'react'
import Nav from './Nav';
import '../css/header.css';

class Header extends Component {
  render() {
    return (
      <div id="header">
        <Nav menuOpen={this.props.menuOpen} toggleMenu={this.props.menuToggle}/>
      </div>
    )
  }
}

export default Header;
