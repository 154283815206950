import React, { Component } from 'react';

import '../css/restaurants.css';
class Restaurants extends Component {
  render() {
    return (
      <div id="restaurants">
        <h1 className="underline">Restaurants</h1>
        <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/otto.jpg" />
          <h1>Otto</h1>
        </div>
        <p>Otto Ristorante which opened in March 2000 has the ability to reinvent the flavours of Italy in stylish new 
          forms and has attracted acclaim both internationally and locally. With amazing service and some of Sydney's 
          best Modern Italian cuisine, Otto is at the forefront of Australian dining.</p>
          <div id="restaurantDetails">
            <a href="https://goo.gl/maps/E9HUjm73EA62" target="_blank"><i className="material-icons">location_on</i> Shop 8, 6 Cowper Wharf Road, Woolloomooloo</a>
            <a href="tel:0293687488"><i className="material-icons">phone</i> 02 9368 7488</a>
            <a href="https://ottoristorante.com.au/sydney/" target="_blank"><i className="material-icons">public</i> ottoristorante.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/chinadoll.jpg" />
          <h1>China Doll</h1>
        </div>
        <p>With its spectacular vista of the city skyline, china doll elegantly resides at Sydney’s historic Finger Wharf at 
          Woolloomooloo. This stylishly sleek, award-winning, Iain Halliday designed beauty offers a balanced menu of generously 
          portioned, modern Asian dishes from the talents of Chef Frank Shek.</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/iFxruykvLUN2" target="_blank"><i className="material-icons">location_on</i> Shop 4, 6 Cowper Wharf Road, Woolloomooloo</a>
            <a href="tel:0293806744"><i className="material-icons">phone</i> 02 9380 6744</a>
            <a href="https://chinadoll.com.au" target="_blank"><i className="material-icons">public</i> chinadoll.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/mantra.jpg" />
          <h1>Mantra</h1>
        </div>
        <p>Working together with Head Chef Daniel Hughes, it has been their commitment to source the finest produce available 
          in Australia with particular focus on their seafood and meat, presented with balance and dedication.</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/3EcB54VzUiM2" target="_blank"><i className="material-icons">location_on</i> 6 Cowper Wharf Road, Woolloomooloo</a>
            <a href="tel:0293323822"><i className="material-icons">phone</i> 02 9332 3822</a>
            <a href="https://www.mantarestaurant.com.au" target="_blank"><i className="material-icons">public</i> mantarestaurant.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/kingsleys.jpg" />
          <h1>Kingsleys Steak &amp; Crabhouse</h1>
        </div>
        <p>Established in Sydney, Australia in 1994, Kingsleys Steak &amp; Crabhouse is not just about the food. Pacific 
          Restaurant Group has created a style of restaurant that keeps the clients coming back for more.</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/XwaYmBYLja52" target="_blank"><i className="material-icons">location_on</i> 6 Cowper Wharf Road, Woolloomooloo</a>
            <a href="tel:1300546475"><i className="material-icons">phone</i> 1300 546 475</a>
            <a href="https://kingsleyssydney.com.au/" target="_blank"><i className="material-icons">public</i> kingsleyssydney.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/sienna.jpg" />
          <h1>Sienna Marina</h1>
        </div>
        <p>The Sienna Marina is an Italian restaurant on Cowper Wharf Road. Excellent dining and service and all guests receive a 10% discount (Ask Reception)</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/JbwHRCd9jgC2" target="_blank"><i className="material-icons">location_on</i> Shop 6, 7-41 Cowper Wharf Road, Woolloomooloo</a>
            <a href="tel:0293586299"><i className="material-icons">phone</i> 02 9358 6299</a>
            <a href="http://siennamarina.com.au" target="_blank"><i className="material-icons">public</i> siennamarina.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/tilbury.jpg" />
          <h1>Tilbury Hotel</h1>
        </div>
        <p>The Tilbury Hotel is eminently satisfying, from the cheery greeting as you wander into the 
          ground-floor restaurant to the flourish with which your finishing espresso and cognac are set on the table.</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/3unk2JETfu32" target="_blank"><i className="material-icons">location_on</i> 12-18 Nicholson Street (Cnr Forbes Street), Woolloomooloo</a>
            <a href="tel:0293681955"><i className="material-icons">phone</i> 02 9368 1955</a>
            <a href="https://www.tilburyhotel.com.au/" target="_blank"><i className="material-icons">public</i> tilburyhotel.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
      <div id="restaurantMain">
          <img src="/images/restaurants/fitzroy.jpg" />
          <h1>The Old Fitzroy Hotel</h1>
        </div>
        <p>Originally called “Fitzroy Hotel”, it was renamed “The Revolving Battery” during the 1980s. 
          When it changed hands again, it went back to being “Fitzroy Hotel” but people affectionately 
          referred to it as “the old Fitzroy”, prompting an official name change to what it is today.</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/RWh7u4TUAG62" target="_blank"><i className="material-icons">location_on</i> 129 Dowling Street, Woolloomooloo</a>
            <a href="tel:0293563848"><i className="material-icons">phone</i> 02 9356 3848</a>
            <a href="http://www.oldfitzroy.com.au/" target="_blank"><i className="material-icons">public</i> oldfitzroy.com.au</a>
        </div>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/restaurants/frisco.jpg" />
          <h1>Frisco Hotel</h1>
        </div>
        <p>Just a minutes walk from Mariners Court, great food, friendly staff - cheap and cheerful - definitely "THE LOCAL"</p>
        <div id="restaurantDetails">
            <a href="https://goo.gl/maps/bWmZWtdowJu" target="_blank"><i className="material-icons">location_on</i> 46 Dowling Street, Woolloomooloo</a>
            <a href="tel:0293571800"><i className="material-icons">phone</i> 02 9357 1800</a>
            <a href="https://www.friscohotel.com.au/" target="_blank"><i className="material-icons">public</i> friscohotel.com.au</a>
        </div>
      </div>
      </div>
    )
  }
}

export default Restaurants;