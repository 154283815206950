import React, { Component } from 'react'
import '../css/carousel.css'

class Carousel extends Component {
  render() {
    return (
      <div id="carouselHome" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="bg" src="/images/main/hp2.jpg" alt="First slide"/>
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/IMG_2157.jpg" alt="Second slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/P1030338.JPG" alt="Third slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/P1030339.JPG" alt="Fourth slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/P1030340.JPG" alt="Fifth slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/P1030362.JPG" alt="Sixth slide" />
            </div>
            <div className="carousel-item">
                <img className="bg" src="/images/main/P1030367.JPG" alt="Seventh slide" />
            </div>
        </div>
      </div>
    )
  }
}

export default Carousel;