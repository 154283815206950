import React, { Component } from 'react';

import '../css/restaurants.css';
class Places extends Component {
  render() {
    return (
      <div id="restaurants">
        <h1 className="underline">Places to See</h1>
        <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/places/rbg.jpeg" />
          <h1>Royal Botanic Gardens</h1>
        </div>
        <p>A place of natural beauty where people come for peace, relaxation, education and to learn more about plants and horticulture.
          Open daily at 6.30am and close at sunset. Entry is free.
          <br /><a href="https://www.rbgsyd.nsw.gov.au/" target="_blank">www.rbgsyd.nsw.gov.au/</a>
        </p>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/places/artgallery.jpeg" />
          <h1>Art Gallery of NSW</h1>
        </div>
        <p>It is one of Australia's leading art museums. Enjoy the Gallery's collection of Australian, Aboriginal, European, Asian and contemporary art.
          Admission to the museum is free.
          <br /><a href="https://www.artgallery.nsw.gov.au/"target="_blank">www.artgallery.nsw.gov.au/</a>
        </p>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/places/operahouse.jpg" />
          <h1>Sydney Opera House</h1>
        </div>
        <p>Considered by man to be one of the wonders of the modern world. The Opera House is one of Sydney's most popular icons. It is the home
          to the Australian Ballet, Australian Chamber Orchestra, Opera Australia, Sydney Philharmonia Choirs and Sydney Theatre Company.
          <br /><a href="https://www.sydneyoperahouse.com/" target="_blank">www.sydneyoperahouse.com/</a>
        </p>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/places/harbourbridge.jpg" />
          <h1>Harbour Bridge</h1>
        </div>
        <p>More than an iconic landmark. You can drive it, walk it, or climb it and see the panoramic veiws from the top. 
        <br /><a href="www.bridgeclimb.com.au" target="_blank">www.bridgeclimb.com.au</a></p>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/places/lunapark.jpg" />
          <h1>Luna Park</h1>
        </div>
        <p>Harbour side amusement park with traditional mid-way games and rides. Entry to the park is free however you need to buy tickets for the rides.To save money, buy your tickets for rides online.
        <br /><a href="https://www.lunaparksydney.com/" target="_blank">www.lunaparksydney.com/</a></p>
      </div>

      <div id="restaurantItem">
        <div id="restaurantMain">
          <img src="/images/places/taronga.jpg" />
          <h1>Taronga Zoo</h1>
        </div>
        <p>Features Australia's finest collection of native animals and diverse collection of exotic species. Situated along the waterfront overlooking Sydney Cove
          , Harbour Bridge and the Opera House. Take the ferry from Circular Quay.
          <br /><a href="https://taronga.org.au/sydney-zoo" target="_blank">taronga.org.au/sydney-zoo</a>
        </p>
      </div>
      </div>
    )
  }
}

export default Places;