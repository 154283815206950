import React, { Component } from 'react'

import '../css/footer.css'

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footerinfo"><i className="material-icons">phone</i><p><a href="tel:61293203800">Tel:+ 61 2 9320 3800</a><a href="tel:61293203862">Fax: + 61 293203862</a></p></div>
        <div className="footerinfo"><i className="material-icons">home</i><a href="https://goo.gl/maps/LaSx5bLmPT52" target="_blank">Mariners’ Court Hotel<br/>44-50 McElhone Street, Woolloomooloo NSW 2011</a></div>
        <div className="footerinfo"><i className="material-icons">email</i><a href="mailto:reservations@marinerscourt.com.au">reservations@marinerscourt.com.au</a></div>
    </footer>
    )
  }
}

export default Footer;