import React, { Component } from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom'

import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import Restaurants from './Components/Restaurants';
import Places from './Components/Places';
import Rooms from './Components/Rooms';
import Gallery from './Components/Gallery';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import './App.css';

class App extends Component {

  state = {
    menuOpen: false,
  }

  menuToggle = () => {
    this.setState ({ ...this.state, menuOpen: !this.state.menuOpen});
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Header menuOpen={this.state.menuOpen} menuToggle={this.menuToggle}/>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/restaurants" component={Restaurants} />
          <Route path="/places-to-see" component={Places} />
          <Route path="/rooms" component={Rooms} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/contact" component={Contact} />
          <div className="FooterSpacing">
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
